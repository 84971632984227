.banner_image {
  background-image: url("../../public/assets/svg/landing_page.svg");
  background-size: 500px 450px;
  background-position: center;
  background-repeat: no-repeat;
  height: 450px;
}

.container_spacing {
  padding: 100px !important;
  text-align: center;
}

.leftContainer_spacing {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: center !important;
  text-align: center;
}

.page_heading {
  font-size: 4em;
  margin: 1rem 0;
}

.page_subHeading {
  font-size: 19px;
  color: #626f7f;
  line-height: 28px;
  margin: 1rem 0;
}

.text_decoration {
  text-decoration: none;
}

.contact_boxes {
  border: 1px solid #ddd;
  margin: 1.5rem;
  border-radius: 6px;
  padding: 2rem 1.5rem;
  box-shadow: 0 3px 6px 0 rgb(34 37 37 / 15%);
}

.contact_boxes_title {
  color: #41415a;
}

.contact_boxes_link {
  text-decoration: none !important;
  color: #1976d2;
  display: inline-block;
  margin: 0 0 1rem 0;
}

@media only screen and (max-width: 1024px) {
  .container_spacing {
    padding: 80px 20px !important;
  }

  .banner_image {
    background-image: url("../../public/assets/svg/landing_page.svg");
    background-size: 350px 350px;
    background-position: center;
    background-repeat: no-repeat;
    height: 350px;
  }
  .page_heading {
    font-size: 3em;
  }
}

@media only screen and (max-width: 768px) {
  .container_spacing {
    padding: 100px 100px !important;
  }
  .page_heading {
    font-size: 3em;
  }
}

@media only screen and (max-width: 586px) {
  .container_spacing {
    padding: 100px 20px !important;
  }
  .page_heading {
    font-size: 3em;
  }

  .banner_image {
    margin-top: 40px;
    background-image: url("../../public/assets/svg/landing_page.svg");
    background-size: 300px 300px;
    background-position: center;
    background-repeat: no-repeat;
    height: 300px;
  }
}
