
.container_spacing {
    padding: 100px !important;
}

.leftContainer_spacing{
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: center !important;
}

.page_heading {
    font-size: 44px;
}

.page_subHeading{
    font-size: 19px; 
    color: #626f7f;
    line-height: 28px;
}

.text_decoration{
    text-decoration: none;
}

.model_backDrop{
    width: 400px !important;
}

@media only screen and (max-width: 1024px) {
    .container_spacing {
        padding: 80px 20px !important;
    }
}

@media only screen and (max-width: 800px) {
    .container_spacing {
        padding: 100px !important;
    }
}

@media only screen and (max-width: 768px) {
    .container_spacing {
        padding: 100px 100px !important;
    }
}

@media only screen and (max-width: 586px) {
    .container_spacing {
        padding: 100px 20px !important;
    }
    .model_backDrop{
        width: 70% !important;
    }
}
