.Appbar_container {
  background-color: #ffffff !important;
  color: #333e49 !important;
  padding: 0px 80px !important;
}
.link {
  font-size: 2.5rem;
  font-weight: bold;
  font-family: "Ubuntu", sans-serif;
  text-decoration: none;
  color: #526cfe !important;
}

@media only screen and (max-width: 1024px) {
  .Appbar_container {
    background-color: #ffffff !important;
    color: #333e49 !important;
    padding: 0px 0px !important;
  }
}
