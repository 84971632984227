.banner_image {
    background-image: url('../../public/assets/svg/landing_page.svg');
    background-size: 500px 450px;
    background-position: center;
    background-repeat: no-repeat;
    height: 450px;
}

.container_spacing {
    padding: 100px !important;
}

.leftContainer_spacing{
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: center !important;
}

.page_heading {
    font-size: 4em;
}

.page_subHeading{
    font-size: 19px; 
    color: #626f7f;
    line-height: 28px;
}

.text_decoration{
    text-decoration: none;
}

@media only screen and (max-width: 1024px) {
    .container_spacing {
        padding: 80px 20px !important;
    }

    .banner_image {
        background-image: url('../../public/assets/svg/landing_page.svg');
        background-size: 350px 350px;
        background-position: center;
        background-repeat: no-repeat;
        height: 350px;
    }
    .page_heading {
        font-size: 3em;
    }
}

@media only screen and (max-width: 768px) {
    .container_spacing {
        padding: 100px 100px !important;
    }
    .page_heading {
        font-size: 3em;
    }
}

@media only screen and (max-width: 586px) {
    .container_spacing {
        padding: 100px 20px !important;
    }
    .page_heading {
        font-size: 3em;
    }

    .banner_image {
        margin-top: 40px;
        background-image: url('../../public/assets/svg/landing_page.svg');
        background-size: 300px 300px;
        background-position: center;
        background-repeat: no-repeat;
        height: 300px;
    }
}
