.container_spacing{
    padding: 96px !important;
}

.center_container{
    padding: 32px !important;
}

.form_container{
    padding: 0px 96px !important;
}

.page_heading {
    font-size: 4em;
    text-align: center;
}

.page_subHeading{
    font-size: 1.2em;
    text-align: center;
}

@media only screen and (max-width: 1024px) {
    .container_spacing {
        padding: 80px !important;
    }
    .center_container{
        padding: 0px 80px !important;
    }
    .form_container{
        padding: 0px 0px !important;
    }
    .page_heading {
        font-size: 3em;
        text-align: center;
    }
    
    .page_subHeading{
        font-size: 19px;
        text-align: center;
    }
}

@media only screen and (max-width: 768px) {
    .container_spacing {
        padding: 80px !important;
    }
    .center_container{
        padding: 0px !important;
    }
    .form_container{
        padding: 0px 0px !important;
    }
}

@media only screen and (max-width: 586px) {
    .container_spacing {
        padding: 80px 80px !important;
    }
    .center_container{
        padding: 0px !important;
    }
    .form_container{
        padding: 0px 0px !important;
    }
}

@media only screen and (max-width: 366px) {
    .container_spacing {
        padding: 80px 20px !important;
    }
    .center_container{
        padding: 0px !important;
    }
    .form_container{
        padding: 0px 0px !important;
    }
}